import React, { useState, useEffect } from 'react';

import {Routes, Route, Navigate,useParams} from 'react-router-dom'
import $ from 'jquery';

function Faq() {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFaqs, setFilteredFaqs] = useState([]);

    setTimeout(function (){
    if(window.location.hash){
        const hash = window.location.hash.replace("#","");
        //window.location.hash = Math.random();
        if(document.getElementById(hash).offsetTop){
            var move_sc = document.getElementById(hash).offsetTop - 120;
            console.log(move_sc)

                window.scrollTo(0, move_sc)

        }

    }
    },1000);

    const paymentFaqs = [
        {
            question: "What payment options are available?",
            answer: "We accept most major credit/debit cards (e.g., VISA, MasterCard, American Express, UnionPay) and direct bank transfers."
        },
        {
            question: "Will I receive a discount if I pay via direct bank transfer?",
            answer: "Yes. Customers will enjoy a 2% rebate from their final order price. Bank transfers usually take 2-3 days for clearance/processing time."
        },
        {
            question: "Can I pay using 2 or more separate payment options in one transaction?",
            answer: "Yes. We offer flexible payment options for customers who need them. Please contact us at hello@baemont.com before placing your order."
        },
        {
            question: "How is refund processed?",
            answer: "Once your request for return or cancellation has been approved, our team will send instructions via email on how to proceed with refunds."
        },
        {
            question: "Will I be charged custom duties or taxes?",
            answer: "Custom duties or taxes may apply based on your country’s regulations. Please check with local authorities for accurate details."
        }
    ];

    const deliveryFaqs = [
        {
            question: "How do you do delivery?",
            answer: "We use only trusted courier services for safe and secured delivery. All our orders are insured up to the full value for our customers’ convenience and peace of mind. Tracking number and estimated delivery date will be provided once the order is processed and ready for delivery. All deliveries will require a signature upon receipt for insurance purposes. For security reasons, we do not deliver to any P.O. Boxes or Locked Bags."
        },
        {
            question: "Do you offer free delivery?",
            answer: "Yes, we offer free delivery for eligible orders. Please check our delivery policy for details."
        },
        {
            question: "Do you offer international shipping?",
            answer: "Yes, we offer international shipping to select countries. Shipping fees and delivery times may vary depending on the destination."
        },
        {
            question: "How long does delivery take after I’ve placed an order?",
            answer: "Delivery times vary depending on your location. Typically, orders are delivered within 3-7 business days after processing."
        },
        {
            question: "Can I collect my order in-person?",
            answer: "Yes, in-person collection is available at select locations. Please contact our support team to arrange this."
        },
        {
            question: "Is your delivery fully insured?",
            answer: "Yes, all deliveries are fully insured up to the full value of the order for our customers' peace of mind."
        },
        {
            question: "Can you deliver to an address different from the billing address?",
            answer: "Yes, we can deliver to an address different from the billing address. Please ensure you provide the correct delivery address during checkout."
        }
    ];
    const returnRefundCancellationFaqs = [
        {
            question: "What is your return or exchange policy?",
            answer: "At BAEMONT, we do our best to ensure our customers are given the opportunity and assurance of shopping at our online store safely and securely. As such, all our sales are final. However, we understand that there are unfavorable circumstances that require our attention to fulfill customer requests accordingly. In the unfortunate event that you are concerned with your order (for example, damages, defects, or incorrect engraving), please inform our customer service team via WhatsApp (+6010-363 0381) or email at hello@baemont.com for immediate assistance. The request for return must be within 7 working days from the date received. Upon agreement, any returning items should be in their original packaging and accompanied by original documentation."
        },
        {
            question: "How do I return your product, if accepted?",
            answer: "If your return request is accepted, our customer service team will provide you with detailed instructions on how to return the product. Please ensure the product is in its original packaging and include any required documentation."
        },
        {
            question: "Do you allow placed orders to be cancelled and refunded?",
            answer: "Yes, we allow cancellations and refunds under certain conditions. If you wish to cancel an order, please contact our customer service team as soon as possible. Refunds will be processed if the order has not been dispatched, or in case of accepted returns as per our policy."
        },
        {
            question: "Do you offer an upgrade or exchange?",
            answer: "At this time, we do not offer upgrades or exchanges on placed orders. However, if your order qualifies for a return, you can request a refund or replacement based on the circumstances."
        }
    ];
    const orderRingPackagingFaqs = [
        {
            question: "Can I custom-design my own Engagement Ring?",
            answer: "Yes. We offer the opportunity for our customers to provide their own desired custom design for engagement rings. We typically require a clear picture, sketch, or mock-up of the ring to be submitted to our designers before moulding. If you prefer to use your own custom design for diamond settings, please reach out directly to our Customer Service Representative for assistance."
        },
        {
            question: "Can I resize my ring after I’ve purchased it?",
            answer: "Yes, we offer resizing services for rings purchased from us. Please contact our customer service team for details on resizing and to discuss the process."
        },
        {
            question: "How do I find my ring size?",
            answer: "To find your ring size, we recommend visiting a local jeweler for an accurate measurement. Alternatively, you can use our online ring sizing guide or request a ring sizer from us."
        },
        {
            question: "Do you offer engraving services on my ring?",
            answer: "Yes, we offer engraving services for most of our rings. Please contact us prior to purchasing or during the order process to request an engraving, and we will assist you with available options."
        },
        {
            question: "I like a setting from your website, but I have my own loose diamonds. Can you set my diamond into a BAEMONT ring?",
            answer: "Yes, we can set your loose diamonds into a BAEMONT ring. Please get in touch with our customer service team to discuss your diamond and the setting options available."
        }
    ];
    function mergeFaqs(...faqArrays) {
        return faqArrays.flat();
    }

// Usage example:
    const allFaqs = mergeFaqs(paymentFaqs, deliveryFaqs, returnRefundCancellationFaqs, orderRingPackagingFaqs);
    // Filter FAQs based on search input
    useEffect(() => {

        if (searchTerm.trim() === '') {
            setFilteredFaqs(allFaqs); // Show all if no search term
        } else {
            setFilteredFaqs(
                allFaqs.filter((allFaqs) =>
                    allFaqs.question.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }
    }, [searchTerm]);
  return (
    <div>
        <section className="common-page-header">

            <div className="container">
                <div className="row pageTitle m0">
                    <div className="col-12  text-center">
                        <h1 className="display-3 text-white">FAQs | How Can We Help?</h1>
                        <ul className="breadcrumb text-center d-block">
                            <li className="d-inline-block "><a className="text-white" href="index.html">HOME</a><span className="mx-2">/</span></li>
                            <li className="d-inline-block"> FAQs | How Can We Help?</li>
                        </ul>

                    </div>
                </div>
            </div>
        </section>


 <section className="my-5">
    <div className="container">
        <div className="row">
            <div className="col-md-8">
                <div className="w-100 me-3" role="search">
                    <input
                        type="search"
                        className="form-control"
                        placeholder="Search..."
                        aria-label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                {searchTerm.trim() === '' ?(
                        <>

                            <div className="faq-text">
                                <h3>Payment</h3>

                                <div className="accordion" id="payment">
                                    {paymentFaqs.map((faq, index) => (
                                        <div className="accordion-item faq-item" key={index}>
                                            <h2 className="accordion-header" id={`payment${index + 1}`}>
                                                <button
                                                    className="accordion-button title-faq collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#paymentcollapse${index + 1}`}
                                                    aria-expanded="false"
                                                    aria-controls={`paymentcollapse${index + 1}`}
                                                >
                                                    {faq.question}
                                                </button>
                                            </h2>
                                            <div
                                                id={`paymentcollapse${index + 1}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`payment${index + 1}`}
                                                data-bs-parent="#payment"
                                            >
                                                <div className="accordion-body">
                                                    {faq.answer}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="faq-text">
                                <h3>Delivery</h3>

                                <div className="accordion" id="delivery">
                                    {deliveryFaqs.map((faq, index) => (
                                        <div className="accordion-item faq-item" key={index}>
                                            <h2 className="accordion-header" id={`delivery${index + 1}`}>
                                                <button
                                                    className="accordion-button title-faq collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#deliverycollapse${index + 1}`}
                                                    aria-expanded="false"
                                                    aria-controls={`deliverycollapse${index + 1}`}
                                                >
                                                    {faq.question}
                                                </button>
                                            </h2>
                                            <div
                                                id={`deliverycollapse${index + 1}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`delivery${index + 1}`}
                                                data-bs-parent="#delivery"
                                            >
                                                <div className="accordion-body">
                                                    {faq.answer}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="faq-text">
                                <h3>Return, Refund and Cancellation</h3>

                                <div className="accordion" id="return">
                                    {returnRefundCancellationFaqs.map((faq, index) => (
                                        <div className="accordion-item faq-item" key={index}>
                                            <h2 className="accordion-header" id={`return${index + 1}`}>
                                                <button
                                                    className="accordion-button title-faq collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#returncollapse${index + 1}`}
                                                    aria-expanded="false"
                                                    aria-controls={`returncollapse${index + 1}`}
                                                >
                                                    {faq.question}
                                                </button>
                                            </h2>
                                            <div
                                                id={`returncollapse${index + 1}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`return${index + 1}`}
                                                data-bs-parent="#return"
                                            >
                                                <div className="accordion-body">
                                                    {faq.answer}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="faq-text">
                                <h3>Order, Ring and Packaging</h3>

                                <div className="accordion" id="order">
                                    {orderRingPackagingFaqs.map((faq, index) => (
                                        <div className="accordion-item faq-item" key={index}>
                                            <h2 className="accordion-header" id={`order${index + 1}`}>
                                                <button
                                                    className="accordion-button title-faq collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#ordercollapse${index + 1}`}
                                                    aria-expanded="false"
                                                    aria-controls={`ordercollapse${index + 1}`}
                                                >
                                                    {faq.question}
                                                </button>
                                            </h2>
                                            <div
                                                id={`ordercollapse${index + 1}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`order${index + 1}`}
                                                data-bs-parent="#orderorder"
                                            >
                                                <div className="accordion-body">
                                                    {faq.answer}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </>

                    ) : (
                        <div className="faq-text">
                            <h3>Search Faq</h3>

                            <div className="accordion" id="searchfaq">
                                {filteredFaqs.map((faq, index) => (
                                    <div className="accordion-item faq-item" key={index}>
                                        <h2 className="accordion-header" id={`searchfaq${index + 1}`}>
                                            <button
                                                className="accordion-button title-faq collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#searchfaqcollapse${index + 1}`}
                                                aria-expanded="false"
                                                aria-controls={`searchfaqcollapse${index + 1}`}
                                            >
                                                {faq.question}
                                            </button>
                                        </h2>
                                        <div
                                            id={`searchfaqcollapse${index + 1}`}
                                            className="accordion-collapse collapse"
                                            aria-labelledby={`searchfaq${index + 1}`}
                                            data-bs-parent="#searchfaq"
                                        >
                                            <div className="accordion-body">
                                                {faq.answer}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                }


            </div>
            <div className="col-md-4">
                <div className="right-faq-sec">
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=60103630381" className="btn faq-btn">
                        Chat Now
                    </a>
                    <p>Get instant answers to your questions.</p>
                </div>
                <div className="right-faq-sec">
                    <a href="mailto:hello@baemont.com" className="btn faq-btn">
                        Email Us
                    </a>
                    <p>Email us at hello@baemont.com and we’ll try our best to address all the questions within two
                        business days.</p>
                </div>
                <div className="right-faq-sec">
                    <a href="tel:+60103630381" className="btn faq-btn">
                        Call Us
                    </a>
                    <p>Tel: +6010-363 0381.</p>
                </div>


            </div>
        </div>
    </div>
 </section>

    </div>
  )
}

export default Faq